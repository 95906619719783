@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
  background-color: #e5dcdc;
}

::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}
::-webkit-scrollbar-button {
  background: #ccc;
}
::-webkit-scrollbar-track-piece {
  background: #888;
}
::-webkit-scrollbar-thumb {
  background: #eee;
}

/* .cat_container:has(.product_results:empty) {
  display: none;
} */
